import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Text } from '@twilio-paste/text';
import { Separator } from '@twilio-paste/separator';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@twilio-paste/tabs';
import { Table, THead, TBody, Td, Th, Tr } from '@twilio-paste/table';
import { SiteLink } from '../../components/SiteLink.tsx';
import { Breadcrumb, BreadcrumbItem } from '../../components/breadcrumb';
import { SidebarCategoryRoutes } from '../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../components/callout';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/patterns/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
  <Breadcrumb mdxType="Breadcrumb">
    <BreadcrumbItem to="/" mdxType="BreadcrumbItem">Home</BreadcrumbItem>
  </Breadcrumb>
  <Heading as="h1" variant="heading10" mdxType="Heading">{props.pageContext.frontmatter.title}</Heading>
    </content>
    <hr></hr>
    <contentwrapper>
      <content>
        <h2>{`What is a pattern?`}</h2>
        <p>{`Patterns outline how to bring a collection of components together into a reusable solution that solves customer problems and delivers cohesion across our platforms. They are set of guidelines around a particular user flow or interaction that designers and engineers should follow when creating customer experiences.`}</p>
        <p>{`There are two primary goals of patterns:`}</p>
        <ul>
          <li parentName="ul">{`To drive efficiency across Twilio product teams by ensuring that no one needs to solve the same problem twice.`}</li>
          <li parentName="ul">{`To establish cohesion across the full Twilio platform so that users do not need to re-learn how to perform certain actions as they move from product to product.`}</li>
        </ul>
        <h2>{`Patterns`}</h2>
        <Tabs selectedId="available-patterns" baseId="patterns-tabs" mdxType="Tabs">
  <TabList aria-label="Pattern tabs" mdxType="TabList">
    <Tab id="available-patterns" mdxType="Tab">Available patterns</Tab>
    <Tab mdxType="Tab">Most requested patterns</Tab>
  </TabList>
  <TabPanels paddingTop="space20" mdxType="TabPanels">
    <TabPanel mdxType="TabPanel">
      <Box marginBottom="space60" mdxType="Box">
        <Card mdxType="Card">
          <Heading as="h4" variant="heading40" mdxType="Heading">
            Pattern status details
          </Heading>
          <Heading as="h5" variant="heading50" mdxType="Heading">
            Alpha
          </Heading>
          <Text as="p" marginBottom="space50" mdxType="Text">
            Pattern is currently incubating with a contributing team and should not be used in other customer-facing
            products without guidance from the Design Systems team.
          </Text>
          <Heading as="h5" variant="heading50" mdxType="Heading">
            Beta
          </Heading>
          <Text as="p" marginBottom="space30" mdxType="Text">
            Pattern can be used in customer-facing products but should be usability tested in new scenarios to identify
            any further risks, especially in critical customer journeys.
          </Text>
          <Text as="p" marginBottom="space50" fontStyle="italic" mdxType="Text">
            Please reach out to us in #help-design-system if you plan on user testing a beta pattern so we can include
            any research insights in the patterns guidelines.
          </Text>
          <Heading as="h5" variant="heading50" mdxType="Heading">
            Production
          </Heading>
          <Text as="p" mdxType="Text">Pattern is stable.</Text>
        </Card>
      </Box>
      <Box marginBottom="space60" mdxType="Box">
        <Table tableLayout="fixed" mdxType="Table">
          <THead mdxType="THead">
            <Tr mdxType="Tr">
              <Th mdxType="Th">Pattern name</Th>
              <Th mdxType="Th">Description</Th>
              <Th mdxType="Th">Status</Th>
            </Tr>
          </THead>
          <TBody mdxType="TBody">
            <Tr mdxType="Tr">
              <Td mdxType="Td">
                <Anchor href="/patterns/create" mdxType="Anchor">Create</Anchor>
              </Td>
              <Td mdxType="Td">How to enable a user to generate a new object.</Td>
              <Td mdxType="Td">Alpha</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">
                <Anchor href="/patterns/delete" mdxType="Anchor">Delete</Anchor>
              </Td>
              <Td mdxType="Td">How to enable a user to destroy an object.</Td>
              <Td mdxType="Td">Alpha</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">
                <Anchor href="/patterns/notifications" mdxType="Anchor">Notifications and feedback</Anchor>
              </Td>
              <Td mdxType="Td">How to communicate timely information to users.</Td>
              <Td mdxType="Td">Alpha</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">
                <Anchor href="/patterns/object-details" mdxType="Anchor">Object details</Anchor>
              </Td>
              <Td mdxType="Td">How to show a read-only page or panel of information about an object.</Td>
              <Td mdxType="Td">Alpha</Td>
            </Tr>
          </TBody>
        </Table>
      </Box>
    </TabPanel>
    <TabPanel mdxType="TabPanel">
      <Paragraph mdxType="Paragraph">
        These patterns have been highly requested by Twilio product designers and engineers and have been ranked based
        on customer impact, ease of work, and how frequently they appear on product UI roadmaps.
      </Paragraph>
      <Callout variant="info" mdxType="Callout">
        <CalloutTitle mdxType="CalloutTitle">Are you working on solutions that use these patterns?</CalloutTitle>
        <CalloutText mdxType="CalloutText">
          This pattern library relies on contributions from folks like you (yes, YOU). If you are using any of these
          patterns in your work, or if you are extra passionate about a particular pattern,{' '}
          <Anchor href="/patterns/contribute" mdxType="Anchor">contribute to the library</Anchor>! It'll be fun. ✨
        </CalloutText>
      </Callout>
      <Box marginBottom="space60" mdxType="Box">
        <Table tableLayout="fixed" mdxType="Table">
          <THead mdxType="THead">
            <Tr mdxType="Tr">
              <Th mdxType="Th">Pattern name</Th>
              <Th mdxType="Th">Description</Th>
              <Th mdxType="Th">Status</Th>
            </Tr>
          </THead>
          <TBody mdxType="TBody">
            <Tr mdxType="Tr">
              <Td mdxType="Td">Display text</Td>
              <Td mdxType="Td">Special text styles for marketing, showing stats, etc.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Filter group</Td>
              <Td mdxType="Td">Controls for filtering lists of objects or data.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Empty states</Td>
              <Td mdxType="Td">How to inform a user that there is no data to display on a page or section.</Td>
              <Td mdxType="Td"><strong>In progress</strong></Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Copy</Td>
              <Td mdxType="Td">How to enable a user to copy a snippet of text, such as an API key or code snippet.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Status indicator</Td>
              <Td mdxType="Td">How to display the status of an object or process.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Small detail text</Td>
              <Td mdxType="Td">Special text styles for small pieces of descriptive text, such as captions.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Component comparisons</Td>
              <Td mdxType="Td">Guidance on when to use certain similar components, such as Combobox versus Select.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Forms</Td>
              <Td mdxType="Td">Guidance for laying out forms and patterns for common form interactions.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Layouts</Td>
              <Td mdxType="Td">Common page layouts, such as Insights pages, and parts of pages, such as standard page headers.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Search</Td>
              <Td mdxType="Td">How to enable a user to search a set of data based on an alphanumeric string.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Code</Td>
              <Td mdxType="Td">How to display code in the UI.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Errors and error states</Td>
              <Td mdxType="Td">How to inform a user when they have encountered different types of errors.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Data grid/table actions</Td>
              <Td mdxType="Td">How to enable a user to perform actions on objects or data in a table layout.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Edit object</Td>
              <Td mdxType="Td">How to make and apply changes to an object.</Td>
              <Td mdxType="Td"><strong>In progress</strong></Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Loading</Td>
              <Td mdxType="Td">Loading states to display to users while data is fetched.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Disclose information</Td>
              <Td mdxType="Td">How to give the user additional information, using something like help text or a tooltip.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Wizard</Td>
              <Td mdxType="Td">How to take a user through a multi-step flow.</Td>
              <Td mdxType="Td"><strong>In progress</strong></Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Country flags</Td>
              <Td mdxType="Td">How and when to use country flags in the UI.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Card actions</Td>
              <Td mdxType="Td">How to enable a user to perform actions on objects in a card layout.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Promotional messaging</Td>
              <Td mdxType="Td">How to promote new features and upsells in the UI.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Data visualization</Td>
              <Td mdxType="Td">Guidance for displaying data in the UI.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Onboarding</Td>
              <Td mdxType="Td">
                How to teach a user how to use a new product or feature.
              </Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Permissions</Td>
              <Td mdxType="Td">How to block access to certain parts of the Twilio UI.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Directional modal</Td>
              <Td mdxType="Td">How to enable a user to move forwards and backwards through a set of modals.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Media player</Td>
              <Td mdxType="Td">How to present media controls, such as pause and play.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Drag and drop</Td>
              <Td mdxType="Td">How to present drag and drop controls, such as a file upload or a WYSIWYG editor.</Td>
              <Td mdxType="Td">Open for contribution</Td>
            </Tr>
            <Tr mdxType="Tr">
              <Td mdxType="Td">Export/Import/Download/Upload</Td>
              <Td mdxType="Td">how to move a file into or out of Twilio.</Td>
              <Td mdxType="Td"><strong>In progress</strong></Td>
            </Tr>
          </TBody>
        </Table>
      </Box>
    </TabPanel>
  </TabPanels>
        </Tabs>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      